export default {
  colors: {
    primary: '#58B7C4',
    background: '#001D44',
    shape: `#FF4800`,
    title: `#58b7c4`,
    text: `#e0e0e0`,
    components: {
      blockquote: {
        background: `#57b7c4`,
        text: `#0b1314`,
      },
    },
  },
};
